.hero {
  align-items: center;
  background: linear-gradient(180deg, $color-yellow-home 8.33%, $color-yellow-home-gradient 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.hero__container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: rem($width-container);
  padding: rem($outer-space-82 $andes-spacing-20 $andes-spacing-40);
  width: 100%;
}

.hero__cont-text {
  width: 100%;
}

.hero__headline {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-title-44);
  font-weight: $font-weight-regular;
  letter-spacing: rem(-0.32px);
  line-height: rem($font-title-45);
  margin-bottom: rem($andes-spacing-32);
}

.hero__copy-text {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  margin-left: rem($andes-spacing-16);
}

.hero__cont-img {
  display: flex;
  justify-content: center;
  padding-top: rem($andes-spacing-40);
  position: relative;
  width: 100%;
}

.hero__item-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style-type: none;
  margin-bottom: rem($andes-spacing-16);
  width: 100%;
}

.hero__item-list-ico {
	fill: $color-pink;
}

.hero__img-mobile {
  display: block;
  max-width: rem($image-mobile-hero);
  width: 100%;
}

.button-hero {
  background: $color-blue;
  border: none;
  color: $color-white;
  cursor: pointer;
  margin-top: rem($andes-spacing-16);
  width: 100%;
}

.button-hero:hover {
  background: $color-pink;
}
