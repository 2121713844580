@media (width >= 600px) {
  .cards-list-item__card-cont {
    height: $card-height-300;
  }
}

@media (width >= 768px) {
  .andes-modal--small.andes-modal--asset .andes-modal__header {
    padding: 0;
  }

  .andes-modal--small
    .andes-modal__header.andes-modal__header--secondary
    .andes-modal__close-button {
    margin: rem($andes-spacing-8) rem($andes-spacing-24);
  }
}

@media (width >= 1024px) {
  .brands {
    padding-top: rem($inner-space-206);
  }

  .displayed {
    animation: 0.5s ease-in 0s 1 slideInFromLeft;
    display: none;
  }

  .andes-modal__header {
    padding: 0;
  }
}
