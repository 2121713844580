@media (width <= 1023px) {
  .background-components {
    padding-bottom: rem($andes-spacing-20);
  }
}

@media (width <= 1200px) {
  .second-layer {
    background-size: 73%;
    padding: rem($inner-space-60 $andes-spacing-20 0);
  }
}

@media (width <= 991px) {
  .hero-partner__container {
    background-size: 80%;
    flex-direction: column;
    min-height: auto;
    padding: rem($inner-space-60 $andes-spacing-20 $inner-space-34);
  }

  .second-layer {
    background-size: 80%;
  }

  .hero-partner__cont-text {
    align-self: flex-start;
    margin-bottom: rem($andes-spacing-40);
    width: 75%;
  }

  .hero-partner__title {
    font-size: rem($font-title-44);
    line-height: rem($font-title-45);
  }

  .background-components {
    background: linear-gradient(
      183.89deg,
      $color-blue 15.68%,
      $color-pink 97.02%
    );
  }
}

@media (width <= 767px) {
  .hero-partner__container {
    padding: rem($inner-space-60 0 $inner-space-34);
  }

  .second-layer {
    background-size: 90%;
  }
}

@media (width <= 600px) {
  .second-layer {
    background-position: top right;
    background-size: 120%;
  }
}

@media (width <= 480px) {
  .second-layer {
    background-position: top center;
    background-size: 160%;
    padding: rem($andes-spacing-20);
  }

  .hero-partner__cont-text {
    align-self: flex-start;
    margin-bottom: rem($andes-spacing-40);
    width: 95%;
  }
}
