.connect {
  display: flex;
  background: $color-pink;
  padding-bottom: rem($inner-space-60);
}

.agency {
  background: linear-gradient(
    180deg,
    rgb(238 238 238 / 0%) 0%,
    rgb(10 0 128 / 25%) 100%
  );
}

.studios {
  background: $color-blue;
}

.connect-content {
  margin: 0 auto;
  max-width: rem($width-container);
  width: 100%;
}

.connect-bread {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-body-24);
  font-style: normal;
  font-weight: $font-weight-600;
  letter-spacing: rem($letter-space);
  line-height: rem($font-body-28);
  margin-top: rem($inner-space-30);
  text-transform: uppercase;
}

.connect-bread--bread-pink {
  color: $color-pink;
}

.connect-title {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-title-45);
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-space);
  line-height: rem($font-title-45);
  margin-bottom: rem($andes-spacing-20);
  margin-top: rem($andes-spacing-12);
  max-width: rem($cont-660);
  width: 100%;
}

.connect-title--title-blue {
  color: $color-blue;
}

.connect-subtitle {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-24);
  margin-bottom: rem($inner-space-75);
  max-width: 55%;
}

.connect-subtitle--subtitle-grey {
  color: $color-grey;
}
