@media (max-width: 1100px) {
  .learning { 
    padding: rem($andes-spacing-40) 0;
  }

  .learning__cont-cards {
    padding: 0 rem($andes-spacing-20);
  }

  .learning-card {
    width: 32.04%;
  }
}

@media (max-width: 1024px) {
  .learning-card {
    width: 48.5%;
  }
}

@media (max-width: 720px) {
  .learning__cont-cards {
    flex-direction: column;
  }

  .learning-card__title {
    font-size: rem($font-title-32);
  }

  .learning-card__content-card {
    padding: rem($andes-spacing-20);
    position: relative;
    z-index: 10;
  }

  .learning-card {
    max-height: rem($cont-110);
    width: 100%;
  }

  .show-card {
    height: 100%;
    max-height: rem($width-container-600);
    transition: .4s;
  }  

  .hide-card {
    height: 100%;
    max-height: rem($inner-space-100);
    transition: .4s;
  }  

  .learning-card__card-btn {
    max-width: initial;
  }

  .learning-card__image-card {
    height: rem($cont-250);
    position: relative;
  }
  
  .hide-image {
    height: 0;
    padding: 0;
    transition: .4s;
  }

  .learning-card__text-card {
    height: 0;
    min-height: auto;
  }
  
  .show-text {
    height: auto;
  }
}
