.studio-button {
  align-items: center;
  background: $color-pink;
  border-radius: rem($border-radius-5);
  border: none;
  color: $color-white;
  display: flex;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  height: rem($inner-space-44);
  justify-content: center;
  letter-spacing: rem($letter-spacing-3);
  line-height: rem($font-body-24);
  margin: 0 auto;
  margin-bottom: rem($inner-space-35);
  margin-top: rem($inner-space-70);
  max-width: rem($cont-351);
  text-decoration: none;
  width: 100%;
}

.studio-cont {
  margin: 0 auto;
  width: 100%;
}

.studio-cont__photograph-slide-img-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 rem($andes-spacing-20);
}

.studio-cont__url-link {
  text-decoration: none;
}

.studio-cont__photograph-slide-img {
  background-color: $color-white;
  border-radius: 100%;
  height: rem($cont-130);
  margin-right: rem($andes-spacing-24);
  object-fit: contain;
  width: rem($cont-130);
}

.studio-cont__photograph-card {
  align-items: center;
  border-radius: rem($border-radius-8);
  display: flex;
  height: rem($cont-134);
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
}

.studio-cont__photograph-card-title {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  max-width: rem($inner-space-180);
  font-weight: $font-weight-regular;
  text-align: left;
  display: inline-block;
  line-height: rem($font-body-24);
  margin: rem($andes-spacing-8) 0;
}

.studio-cont .slick-track {
  align-items: center;
  display: flex;
}

.studio-cont .slick-dots li {
  height: rem($inner-space-8);
  width: rem($inner-space-8);
}

.studio-cont .slick-dots li button {
  height: rem($inner-space-8);
  width: rem($inner-space-8);
}

.studio-cont .slick-dots li button::before {
  border-radius: 100%;
  border: 1px solid $color-white;
  font-size: 0;
  height: rem($inner-space-8);
  opacity: 1;
  width: rem($inner-space-8);
}

.studio-cont .slick-dots li.slick-active button::before {
  background: $color-white;
  color: $color-white;
}

.studio-cont .slick-dots {
  bottom: rem(-$inner-space-36);
}
