.prices {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.prices__container {
  height: 100%;
  max-height: rem($cont-490);
  max-width: rem($width-container);
  overflow-y: hidden;
  padding: 0 rem($andes-spacing-20);
  text-align: center;
  width: 100%;
}

.prices__container--button-show {
  margin-top: rem($margin-space-50);
}

.prices__table-cont {
  animation: openTable ease-in-out 0.6s 1 normal forwards;
  height: 100%;
  max-height: rem($inner-space-510);
  padding: rem($inner-space-30) 0;
}

.prices__table-cont-mla {
  animation: openTable-mla ease-in-out 0.6s 1 normal forwards;
  height: 100%;
  max-height: rem($cont-490);
  padding: rem($inner-space-30) 0;
}

@keyframes openTable {
  100% {
    height: 100%;
    max-height: rem($table-expand);
  }
}

@keyframes openTable-mla {
  100% {
    height: 100%;
    max-height: rem($table-expand-legal);
  }
}

.prices__table-cont-close {
  animation: closeTable ease-in-out 0.6s 1 normal forwards;
  box-shadow: 0 13px 8px -12px rgb(0 0 0 / 29%);
  height: 100%;
  max-height: rem($table-expand);
  padding: rem($inner-space-30) 0;
}

.prices__table-cont-close-mla {
  animation: closeTable-mla ease-in-out 0.6s 1 normal forwards;
  box-shadow: 0 13px 8px -12px rgb(0 0 0 / 29%);
  height: 100%;
  max-height: rem($table-expand-legal);
  padding: rem($inner-space-30) 0;
}

@keyframes closeTable {
  100% {
    height: 100%;
    max-height: rem($inner-space-510);
  }
}

@keyframes closeTable-mla {
  100% {
    height: 100%;
    max-height: rem($inner-space-485);
  }
}

.terms {
  width: 100%;
  margin-top: rem($andes-spacing-40);
}

.terms__item-list {
  color: $color-grey-7c;
  font-family: $font-family-primary;
  font-size: $font-body-14;
  line-height: rem($font-body-16);
  margin: rem($inner-space-10) 0 0;
  text-align: left;
}

.prices__title-cont {
  max-width: rem($cont-785);
}

.prices__headline {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-title-45);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-space);
  line-height: rem($font-title-46);
  margin-bottom: rem($andes-spacing-24);
  text-align: left;
}

.prices__headline-bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.prices__paragraph {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-space);
  line-height: rem($font-body-24);
  text-align: left;
}

.prices__paragraph-bold {
  font-weight: $font-weight-600;
}

.prices__btn-expand {
  align-items: center;
  background: none;
  border-radius: none;
  border: none;
  color: $color-pink;
  cursor: pointer;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-weight: $font-weight-600;
  justify-content: center;
  line-height: rem($font-body-18);
}

.prices__arrowCont {
  display: inline-block;
  height: rem($chevron-size);
  margin-left: rem($margin-space-5);
  text-align: center;
  vertical-align: bottom;
  width: rem($chevron-size);
}

.prices__arrowDown {
  vertical-align: middle;
}

.prices__arrowUp {
  rotate: 180deg;
  vertical-align: middle;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0;
  border: none;
  text-align: left;
  width: 100%;
}

.table__images {
  max-width: rem($cont-160);
  width: 100%;
}

.table__logos-row {
  padding-bottom: rem($inner-space-30);
  padding: rem($inner-space-30);
  text-align: center;
  width: 18%;
}

.table__logos-row:first-child {
  padding-bottom: rem($inner-space-30);
  padding: rem($inner-space-30);
  text-align: center;
  width: 25%;
}

.table__col-base {
  color: $color-grey-table-cols;
  font-family: $font-family-primary;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  padding: rem($andes-spacing-28 $andes-spacing-16);
  text-align: center;
  width: 18%;
}

.table__background-th {
  background: $bg-table-head;
  border-bottom: rem(6px) solid $color-white;
  box-shadow: rem(-40px 0px 16px -40px) rgb(0 0 0 / 41%) inset;
  color: $color-grey-table;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-18);
  text-align: left;
  width: 25%;
  z-index: -1;
}

.table--background-group {
  z-index: -1;

  &:nth-child(3) {
    background: $bg-table-col3;
    border-bottom: rem(6px) solid $color-white;
    box-shadow: rem(38px -2px 16px -40px) rgb(0 0 0 / 41%) inset;
  }

  &:nth-child(4) {
    background: $bg-table-col4;
    border-bottom: rem(6px) solid $color-white;
  }

  &:nth-child(5) {
    background: $bg-table-col5;
    border-bottom: rem(6px) solid $color-white;
  }
}

.table--last-row {
  border: none;
}

.table--highlight {
  background: $color-yellow-table;
  color: $color-pink;
  font-size: rem($font-body-22);
  font-weight: $font-weight-600;
  letter-spacing: rem($letter-space);
  line-height: rem($font-body-22);
  padding: rem($inner-space-36 $andes-spacing-24);
  position: relative;
  text-align: center;
}

.table--highlight::after {
  background: $color-white;
  bottom: rem(3px);
  content: '';
  height: rem(1px);
  left: 12%;
  position: absolute;
  width: 75%;
}

.table__titles-col strong{
  font-weight: $font-weight-600;
}

.table--top-flap::before {
  background: $color-yellow-table;
  border-top-left-radius: rem($border-radius-15);
  border-top-right-radius: rem($border-radius-15);
  content: '';
  height: rem(13px);
  left: 0;
  position: absolute;
  top: rem(-12px);
  width: 100%;
}

.table--bottom-flap::after {
  background: $color-yellow-table;
  border-bottom-left-radius: rem($border-radius-15);
  border-bottom-right-radius: rem($border-radius-15);
  bottom: rem(-14px);
  box-shadow: 0 7px 7px -4px rgb(0 0 0 / 41%);
  content: '';
  height: rem(15px);
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}
