.cards-list-item {
  border-radius: rem($andes-spacing-12);
  display: block;
  margin: 0 rem($andes-spacing-12);
}

.cards-list-item__card-cont {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: rem($card-height-300);
  justify-content: center;
  padding: rem($andes-spacing-24);
  position: relative;
  transition: 1s;

  &:hover {
    background: rgb(0 0 0 / 50%);
    border-radius: rem($andes-spacing-12);
    transition: 0.2s;
  }

  &:hover .displayed {
    display: flex;
  }
}

.cards-list-item__card-cont--mobile {
  background: rgb(0 0 0 / 50%);
  border-radius: rem($andes-spacing-12);
}

//mla
.cards-list-item--longvie {
  background: $cases-mla-1;
  background-size: cover;
}

.cards-list-item--legion-extranjera {
  background: $cases-mla-2;
  background-size: cover;
}

.cards-list-item--regina {
  background: $cases-mla-3;
  background-size: cover;
}

.cards-list-item--cebra {
  background: $cases-mla-4;
  background-size: cover;
}

//mlc
.cards-list-item--eforest {
  background: $cases-mlc-1;
  background-size: cover;
}

.cards-list-item--happy-store {
  background: $cases-mlc-2;
  background-size: cover;
}

.cards-list-item--lhua-store {
  background: $cases-mlc-3;
  background-size: cover;
}

//mco
.cards-list-item--tecnoplaza {
  background: $cases-mco-1;
  background-size: cover;
}

.cards-list-item--celulares-99 {
  background: $cases-mco-2;
  background-size: cover;
}

.cards-list-item--bebesitos {
  background: $cases-mco-3;
  background-size: cover;
}

.cards-list-item--croydon {
  background: $cases-mco-4;
  background-size: cover;
}

//mlm
.cards-list-item--friso {
  background: $cases-mlm-1;
  background-size: cover;
}

.cards-list-item--wrangler {
  background: $cases-mlm-2;
  background-size: cover;
}

.cards-list-item--montepio {
  background: $cases-mlm-3;
  background-size: cover;
}

.cards-list-item--rheem {
  background: $cases-mlm-4;
  background-size: cover;
}

//mlb
.cards-list-item--azeite-andorinha {
  background: $cases-mlb-1;
  background-size: cover;
}

.cards-list-item--turma-da-monica {
  background: $cases-mlb-2;
  background-size: cover;
}

.cards-list-item--comercial-textil {
  background: $cases-mlb-3;
  background-size: cover;
}

.cards-list-item--mae-terra {
  background: $cases-mlb-4;
  background-size: cover;
}

.displayed {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  opacity: 1;
  padding: rem($andes-spacing-20);
  position: absolute;
  text-align: center;
  transition: 5s;
  width: 100%;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.cta-card {
  background: $color-white;
  border-radius: rem($border-radius-5);
  border: none;
  box-sizing: border-box;
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-body-15);
  font-weight: $font-weight-medium;
  padding: rem($inner-space-10 $inner-space-15);
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}

.cards-list-item__card-logo {
  max-width: rem($cont-160);
  width: 100%;
}

.button-modal-cont {
  bottom: 0;
  left: 0;
  margin: 0;
  max-width: 600px;
  padding: rem($andes-spacing-12) 0;
  position: fixed;
  width: 100%;
}

.button-modal {
  background: $color-blue;
  border-radius: rem($border-radius-5);
  border: none;
  color: $color-white;
  cursor: pointer;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-body-15);
  margin: 0 auto;
  max-width: max-content;
  padding: rem($inner-space-10 $inner-space-15);
  text-decoration: none;
}

.button-modal:hover {
  background: $color-pink;
  color: $color-white;
}

.image-modal {
  max-width: rem($width-container-600);
  width: 100%;
}

.andes-modal__header--show {
  background: none;
  box-shadow: none;
}

.andes-modal__close-button {
  background: $color-white;
  border-radius: 50%;
  border: 1px solid rgb(0 0 0 / 55%);
  font-size: rem(17px);
  line-height: 0;
}

.andes-modal__scroll::-webkit-scrollbar {
  background: $hero-gray;
  width: rem($andes-spacing-8);
}

.andes-modal__scroll::-webkit-scrollbar-thumb {
  background: $bg-table-col5;
  border-radius: rem($andes-spacing-8);
  height: rem($andes-spacing-8);
}
