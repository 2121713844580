@media (width >= 1024px) {
  .container {
    padding: 0;
  }

  .design {
    margin-bottom: rem($outer-space-125);
    margin-top: rem($inner-space-50);
  }

  .design__title-cont {
    margin-bottom: rem($outer-space-36);
  }

  .design__headline {
    @include text-font(
      $colorTypo: $color-grey-text,
      $defaultWeight: $font-weight-regular,
      $lineHeight: $font-title-46,
      $size: $font-title-45
    );

    width: 75%;
  }

  .design__paragraph {
    margin: 0;
    width: 50%;
  }

  .design-cols-cont {
    align-items: flex-end;
    background: none;
    justify-content: space-between;
  }

  .design-cols-cont__cont-text {
    margin-top: 0;
  }

  .design-cols-cont__cont-img {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
}
