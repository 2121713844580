@media (width <= 1100px) {
  .ebooks-gallery__gallery-cont {
    margin: rem($andes-spacing-20) auto;
    padding: 0 rem($andes-spacing-20);
  }
}

@media (width <= 1023px) {
  .ebooks-gallery__ebook-card {
    width: 50%;
  }

  .ebooks-gallery__gallery-ebooks {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0, 0.77, 0.51, 0.99);
  }

  .ebooks-gallery__gallery-cont-title {
    margin-bottom: 0;
    padding-bottom: rem($andes-spacing-12);
  }

  .ebooks-gallery__gallery-ebooks-show {
    cursor: pointer;
    height: auto;
    max-height: rem($accordion-height);
    transition: all 0.8s cubic-bezier(1, 0.29, 0.23, 0.66);
  }
}

@media (width <= 767px) {
  .ebooks-gallery__gallery-title {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}

@media (width <= 480px) {
  .ebooks-gallery__ebook-card {
    width: 100%;
  }
}
