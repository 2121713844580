.learn-hero {
  padding-top: rem($inner-space-80);
  width: 100%;
}

.learn-hero__cont-hero {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: rem($width-container);
  width: 100%;
}

.learn-hero__image {
  max-width: rem($inner-space-500);
  text-align: center;
  width: 100%;
  z-index: 1;
}

.learn-hero__img-class {
  min-width: rem($img-cont-459);
  width: 100%;
}

.learn-hero__content {
  width: 100%;
  z-index: 1;
}

.learn-hero__title {
  color: $color-pink;
  font-family: $font-family-primary;
  font-size: rem($font-title-70);
  font-weight: $font-weight-600;
  letter-spacing: rem(-$letter-spacing-8);
  line-height: rem($font-title-65);
  margin-bottom: rem($andes-spacing-32);
}

.learn-hero__title--bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.learn-hero__text {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-24);
  margin-bottom: rem($inner-space-30);
  width: 85%;
}
