.tabs {
  margin: 0 auto;
  max-width: $width-container;
  width: $width-100-percent;
}

.tabs__btn-group {
  border-radius: rem($border-radius-8);
  border: rem($partners-border-secondary) solid $partners-border-color;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: rem($tabs-btn-group-height);
  margin-bottom: rem($outer-space-22);
  margin-top: rem($outer-space-25);
  overflow: hidden;
  width: $width-100-percent;
}

.tabs__btn-group--mlb {
  grid-template-columns: 1fr 1fr;
}

.tabs__btn {
  align-items: center;
  background: $color-white;
  border: none;
  border-right: rem($partners-border-secondary) solid $partners-border-color;
  color: $color-grey;
  cursor: pointer;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-size-16);
  font-style: normal;
  font-weight: $font-weight-bold;
  gap: rem($tabs-btn-gap);
  height: $height-100-percent;
  justify-content: center;
  letter-spacing: rem($letter-spacing-2);
  line-height: 134.77%;
  text-transform: uppercase;
}

.tabs__btn span {
  align-items: center;
  background: $color-pink;
  border-radius: $height-100-percent;
  color: $color-white;
  display: flex;
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-bold;
  height: rem($tabs-btn-span-height);
  justify-content: center;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($inner-space-28);
  width: rem($tabs-btn-span-width);
}

.tabs__btn:first-child {
  border-radius: rem($border-radius-8) 0 0 rem($border-radius-8);
  border-right: rem($partners-border-secondary) solid $partners-border-color;
}

.tabs__btn:last-child {
  border-right: none;
}

.tabs__btn--active {
  background: rgb(231 59 144 / 10%);
  color: $color-pink;
}

.tabs .slick-list {
  margin: 0 rem(-$outer-space-10);
}

.tabs .slick-slide > div {
  padding: 0 rem($outer-space-10);
}

.tabs__card {
  background: $tabs-card-background;
  border-radius: rem($border-radius-18);
  border: rem($partners-border-secondary) solid $partners-border-color;
  margin-bottom: rem($outer-space-62);
  padding: rem($tabs-card-padding);
}

.tabs__card-head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: $width-100-percent;
}

.tabs__card-content {
  width: $width-50-percent;
}

.tabs__card-title {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-title-45);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($partners-title-line-height);
  margin-bottom: rem($outer-space-34);
}

.tabs__card-subtitle {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: $font-body-22;
  font-style: normal;
  font-weight: $font-weight-bold;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($text-space-24);
  margin-bottom: rem($outer-space-25);
}

.tabs__card-description {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: $font-size-20;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($text-space-24);
}

.tabs__card-items {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($text-space-30);
}

.tabs__card-item {
  display: -webkit-inline-box;
  gap: rem($tabs-card-item-gap);
}

.tabs__card-item-text {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-normal;
}

.tabs__card-item img {
  position: relative;
  top: rem($inner-space-7);
}

.tabs__card-video {
  padding-left: rem($inner-space-35);
  width: $width-50-percent;
}

.tabs__card-video iframe {
  height: rem($height-300);
  width: $width-100-percent;
}

.tabs__card-footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: rem($outer-space-48);
}

.tabs__card-count {
  align-items: center;
  display: flex;
  gap: rem($tabs-card-count-gap);
  width: $width-50-percent;
}

.tabs__card-count-divider {
  background-color: $color-blue;
  height: rem($tabs-card-count-divider-height);
  width: rem($partners-border-secondary);
}

.tabs__card-count-icon-number {
  align-items: center;
  display: flex;
  gap: rem($inner-space-14);
}

.tabs__card-count-item-icon {
  align-items: center;
  background: $color-white;
  border-radius: $border-radius-100;
  box-shadow: rem(0px 0px 4px) rgb(0 0 0 / 25%);
  display: flex;
  height: rem($height-45);
  justify-content: center;
  width: rem($width-45);
}

.tabs__card-count-item-number {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: $font-title-45;
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($partners-title-line-height);
}

.tabs__card-count-item-title {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: $font-size-28;
  font-style: normal;
  font-weight: $font-weight-medium;
  letter-spacing: rem($letter-spacing-1);
  line-height: $height-100-percent;
  margin-top: rem($outer-space-8);
  text-transform: uppercase;
}

.tabs__card-count-item-subtitle {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: $font-size-14;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: $height-100-percent;
  margin-top: rem($outer-space-8);
}

.tabs__card-experience {
  display: none;
}

.tabs__card-experience-video {
  display: none;
}

.tabs__card-alert {
  align-items: center;
  background: $color-white;
  border-radius: rem($border-radius-12);
  box-shadow: rem(0px 0px 8px) rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  gap: rem($inner-space-15);
  padding: rem(20px 50px 38px);
  text-align: center;
  width: $width-50-percent;
}

.tabs__card-alert-title {
  color: $color-pink;
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: rem($text-space-20);
  text-align: center;
}

.tabs_card-message-rectangle {
  display: none;
}

.tabs__card-alert-triangle {
  display: none;
}

.tabs__card-alert-button {
  background: $color-pink;
  border-radius: rem($border-radius-5);
  border: none;
  color: $color-white;
  cursor: pointer;
  font-family: $font-family-primary;
  font-size: $font-body-15;
  font-style: normal;
  font-weight: $font-weight-normal;
  letter-spacing: rem($letter-spacing-3);
  line-height: 134.77%;
  padding: rem($inner-space-12) 0;
  text-decoration: none;
  transition: 0.3s background-color ease;
  width: $width-100-percent;
}

.tabs__card-alert-button:hover {
  background: $color-pink-hover;
}

.tabs__card-advice {
  background: $color-white;
  border-radius: rem($border-radius-8);
  border: rem($partners-border-secondary) solid $color-pink;
  max-width: rem($width-408);
  position: relative;
}

.tabs__card-advice-padding {
  padding: rem(50px 36px 45px);
}

.tabs__card-advice::before {
  background-color: $color-pink;
  content: '';
  display: block;
  height: rem($partners-border-secondary);
  position: relative;
  top: rem($inner-space-16);
  width: $width-100-percent;
}

.tabs__card-advice-title {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-body-35);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($text-space-32);
  margin-bottom: rem($outer-space-31);
}

.tabs__card-advice-subtitle {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: $partners-item-line-height;
}

.tabs__card-advice-headset {
  align-items: center;
  background: $color-pink;
  border-radius: $border-radius-100;
  display: flex;
  height: rem($width-55);
  justify-content: center;
  position: absolute;
  right: rem(-$inner-space-10);
  top: rem(-$inner-space-10);
  width: rem($height-55);
}

.tabs__card-slide-footer {
  display: block;
}

.tabs__card-slide-title {
  color: $color-pink;
  font-family: $font-family-primary;
  font-size: $font-body-22;
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($text-space-24);
  margin-bottom: rem($outer-space-30);
  text-align: start;
}

.tabs__card-slide-button {
  align-items: center;
  background: $color-pink;
  border-radius: rem($border-radius-5);
  border: none;
  color: $color-white;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-size-18);
  font-style: normal;
  font-weight: $font-weight-normal;
  justify-content: center;
  letter-spacing: rem($letter-spacing-3);
  line-height: 134.77%;
  margin: 0 auto;
  margin-top: rem($outer-space-65);
  max-width: rem($width-326);
  padding: rem($inner-space-10) 0;
  text-decoration: none;
  width: $width-100-percent;
}

.tabs__card-slide-img-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.tabs__card-slide-img {
  height: $height-100-percent;
  margin: 0 auto;
  max-height: rem($height-80);
  max-width: $width-50-percent;
  object-fit: contain;
}

.tabs__card-slide-footer .slick-track {
  align-items: center;
  display: flex;
}

.tabs__card-slide-footer .slick-dots li {
  height: rem($height-8);
  width: rem($width-8);
}

.tabs__card-slide-footer .slick-dots li button {
  height: rem($height-8);
  width: rem($width-8);
}

.tabs__card-slide-footer .slick-dots li button::before {
  border-radius: $border-radius-100;
  border: rem($partners-border-secondary) solid $color-blue;
  font-family: $font-family-primary;
  font-size: 0;
  height: rem($height-8);
  opacity: 1;
  width: rem($width-8);
}

.tabs__card-slide-footer .slick-dots li.slick-active button::before {
  background: $color-blue;
  color: $color-blue;
}

.tabs__card-slide-footer .slick-dots {
  bottom: rem(-$outer-space-36);
}

.tabs__card-slide-container {
  position: relative;
}

.tabs__card-slide-footer .slick-slider {
  position: relative;
  z-index: 2;
}

.tabs__card-slide-arrows {
  color: $color-blue;
  display: flex;
  width: $width-100-percent;
}

.tabs__card-slide-arrow {
  align-items: center;
  background-color: $color-white;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  height: rem($height-45);
  justify-content: center;
  position: absolute;
  top: $height-50-percent;
  transform: translateY(-$height-50-percent);
  width: rem($width-45);
  z-index: 3;
}

.tabs__card-slide-arrow--left {
  left: rem(-$inner-space-15);
}

.tabs__card-slide-arrow--left svg {
  transform: rotate(180deg);
}

.tabs__card-slide-arrow--right {
  right: rem(-$inner-space-15);
}

.tabs__card-count-mobile {
  background: $color-light-grey;
  border-radius: rem($border-radius-14);
  padding: rem(30px 30px 40px 30px);
  position: relative;
  width: $width-100-percent;
}

.tabs__card-count-mobile-icon-count {
  align-items: center;
  display: flex;
  gap: $outer-space-20;
  justify-content: center;
  width: $width-100-percent;
}

.tabs__card-count-mobile-icon img {
  height: rem($height-55);
}

.tabs__card-count-mobile-count {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-title-70);
  font-style: normal;
  font-weight: $font-weight-bold;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($outer-space-38);
}

.tabs__card-count-mobile-description {
  border-bottom: rem($partners-border-secondary) solid $color-blue;
  border-top: rem($partners-border-secondary) solid $color-blue;
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: 120%;
  margin-top: rem($outer-space-25);
  padding: rem($inner-space-10) 0;
  text-align: center;
}

.tabs__card-count-mobile--active {
  background: $color-blue;
  border-radius: $border-radius-14;
  box-shadow: rem(0px 0px 6px) rgb(0 0 0 / 25%);
  padding: rem($inner-space-30);
  position: relative;
  top: rem(-$inner-space-25);
  width: $width-100-percent;
}

.tabs__card-count-mobile--active .tabs__card-count-mobile-count,
.tabs__card-count-mobile--active .tabs__card-count-mobile-description {
  border-color: $color-white;
  color: $color-white;
}

.tabs__card-count-mobile--active .tabs__card-count-mobile-icon img {
  width: rem($width-70);
}

.tabs__card-item-ico {
 fill: $color-pink;
}
