.bio-libre {
  margin: rem($inner-space-50 0 $inner-space-120);
  width: 100%;
}

.bio-libre__cont-bio {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: rem($width-container);
  width: 100%;
}

.bio-libre__col-text {
  max-width: 55%;
}

.bio-libre__col-img {
  max-width: 45%;
}

.bio-libre__image {
  max-width: rem($width-container-600);
  width: 100%;
}

.bio-libre__title {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-title-45);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($font-title-45);
  margin-bottom: rem($andes-spacing-24);
}

.bio-libre__title--bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.bio-libre__description {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-24);
  margin-bottom: rem($inner-space-30);
}

.bio-libre__list {
  max-width: 70%;
}

.bio-libre__list-item {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: rem($andes-spacing-20);
}

.bio-libre__list-item:last-child {
  margin-bottom: rem($andes-spacing-40);
}

.bio-libre__list-text-ico {
  fill: $color-pink;
}

.bio-libre__list-text {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  margin-left: rem($andes-spacing-16);
  max-width: 90%;
}

.bio-libre__button-bio {
	background: $color-blue;
	border: none;
	color: $color-white;
  border-radius: rem($border-radius-4);
  font-family: $font-family-primary;
  padding: rem($inner-space-10 $inner-space-12);
  text-decoration: none;
}

.bio-libre__button-bio:hover {
	background: $color-pink;
	color: $color-white;
}
