@media (width >= 600px) {
  .button-hero {
    width: auto;
  }

  .hero__cont-text {
    width: 60%;
  }
}

@media (width >= 991px) {
  .hero {
    align-items: center;
    height: 100vh;
    justify-content: center;
  }

  .hero__container {
    flex-direction: row;
  }

  .hero__cont-text {
    width: 55%;
  }

  .hero__cont-img {
    padding: 0;
    width: 45%;
  }
}

@media (width >= 1200px) {
  .hero__cont-text {
    width: 60%;
  }

  .hero__headline {
    font-size: rem($font-title-70);
    line-height: rem($font-title-65);
  }

  .hero__cont-img {
    width: 40%;
  }

  .hero__container {
    padding: 0;
  }
}
