.pricingMobile {
  margin: 0 auto;
  max-width: $width-container;
  padding: rem(0 $inner-space-24);
}

.pricingMobile__title {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: $font-title-34;
  font-style: normal;
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-spacing-4);
  line-height: $outer-space-38;
  margin-bottom: rem($outer-space-28);
}

.pricingMobile__description {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: $font-body-20;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($outer-space-24);
  margin-bottom: rem($outer-space-26);
}

.pricingMobile__description strong {
  font-weight: $font-weight-600;
}

.pricingMobile__subtitle {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($outer-space-24);
  margin-bottom: rem($margin-space-5);
}

.pricingMobile__btn-group {
  background-color: $bg-table-head;
  border-radius: rem($border-radius-20);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: rem($pricing-mobile-btn-group-height);
  width: $width-100-percent;
}

.pricingMobile__btn {
  align-items: center;
  background-color: $bg-table-head;
  border-radius: 19.3333px;
  border: none;
  display: flex;
  height: $width-100-percent;
  justify-content: center;
}

.pricingMobile__btn-img {
  height: rem($pricing-mobile-btn-img-height);
  width: auto;
}

.pricingMobile__btn--active {
  background-color: $bg-table-col3;
  border-radius: rem($border-radius-20);
}

.pricingMobile__table {
  animation: tableMobileOpen ease-in-out 0.6s 1 normal forwards;
  border-spacing: 0;
  display: block;
  margin-bottom: rem($outer-space-25);
  margin-top: rem($margin-space-12);
  max-height: rem($pricing-mobile-table-max-height);
  overflow-y: hidden;
  table-layout: fixed;
}

@keyframes tableMobileOpen {
  0% {
    height: 100%;
    max-height: rem($table-expand);
  }

  100% {
    height: 100%;
    max-height: rem($pricing-mobile-table-max-height);
  }
}

.pricingMobile__table--active {
  animation: closeTableMobile ease-in-out 0.6s 1 normal forwards;
  height: 100%;
}

@keyframes closeTableMobile {
  0% {
    height: 100%;
    max-height: rem($pricing-mobile-table-max-height);
  }

  100% {
    height: 100%;
    max-height: rem($table-expand);
  }
}

.pricingMobile__table thead {
  background-color: $bg-table-head;
  height: rem($pricing-mobile-table-thead-height);
}

.pricingMobile__table td {
  width: $width-33-percent;
}

.pricingMobile__first-th {
  background-color: $color-white;
  color: $color-gray-pricing;
  font-size: rem($font-body-12);
  font-weight: $font-weight-regular;
}

.pricingMobile__first-ms {
  border-radius: rem($pricing-mobile-border 0 0 0);
  color: $color-gray-pricing;
  font-family: $font-family-primary;
  font-size: rem($font-body-12);
  font-weight: $font-weight-regular;
  position: relative;
}

.pricingMobile__first-ms span {
  background-color: $color-gray-pricing;
  display: block;
  height: rem($pricing-mobile-first-ms-span-height);
  position: absolute;
  right: rem($pricing-mobile-first-ms-span-right);
  top: $height-50-percent;
  transform: translateY(-$height-50-percent);
  width: rem($pricing-mobile-first-ms-span-width);
}

.pricingMobile__first-other {
  border-radius: rem(0 $pricing-mobile-border 0 0);
  color: $color-gray-pricing;
  font-family: $font-family-primary;
  font-size: rem($font-body-12);
  font-weight: $font-weight-regular;
}

.pricingMobile__table tbody tr {
  height: rem($pricing-mobile-table-tbody-tr-height);
  background-color: $bg-table-head;
}

.pricingMobile__table tbody td {
  padding: rem(0 $inner-space-6);
}

.pricingMobile__border {
  border-top: rem($pricing-mobile-border-top) solid $color-white;
}

.pricingMobile__border--first {
  border-top: 0;
}

.pricingMobile__center {
  text-align: center;
}

.pricingMobile__main {
  background-color: $color-yellow-table;
  color: $color-pink;
  font-family: $font-family-primary;
  font-size: rem($font-body-13);
  font-style: normal;
  font-weight: $font-weight-600;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($pricing-mobile-main-line-height);
  position: relative;
  text-align: center;
}

.pricingMobile__main--first {
  border-radius: rem($border-radius-8 $border-radius-8 0 0);
}

.pricingMobile__main span {
  background-color: $color-white;
  bottom: rem($pricing-mobile-main-span-bottom);
  height: rem($pricing-mobile-main-span-height);
  left: $width-50-percent;
  position: absolute;
  transform: translateX(-$width-50-percent);
  width: $pricing-mobile-main-span-width;
  z-index: 1;
}

.pricingMobile__border--other {
  background-color: $bg-table-col3;
  color: $pricing-mobile-border-other-color;
  font-family: $font-family-primary;
  font-size: rem($font-body-13);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($pricing-mobile-border-other-line-height);
}

.pricingMobile__border--last {
  border-radius: rem(0 0 $border-radius-8 $border-radius-8);
}

.pricingMobile__left {
  color: $pricing-mobile-border-other-color;
  font-family: $font-family-primary;
  font-size: rem($font-body-11);
  font-style: normal;
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($pricing-mobile-left-line-height);
}

.pricingMobile__show-info {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $color-pink-pricing;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-body-15);
  font-style: normal;
  font-weight: $font-weight-regular;
  gap: rem($pricing-mobile-show-info-gap);
  line-height: rem($pricing-mobile-show-info-line-height);
  margin: 0 auto rem($outer-space-50) auto;
  text-align: center;
}

.arrowUp {
  rotate: 180deg;
}
