.ui-ms-hero-ecosystem {
  position: relative;
}

.ui-ms-hero-ecosystem__container {
  align-items: center;
  display: flex;
  height: $height-100-percent;
  justify-content: space-between;
  margin: 0 auto;
  max-width: rem($width-container);
  position: relative;
}

.ui-ms-hero-ecosystem__content {
  margin-top: rem($inner-space-100);
}

.ui-ms-hero-ecosystem__title {
  color: $color-blue;
  display: inline-block;
  font-family: $font-family-primary;
  font-size: rem($font-title-70);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-5);
  line-height: rem($line-height-65);
  max-width: 73%;
}

.ui-ms-hero-ecosystem__title--title-mlb {
  max-width: 65%;
}

.ui-ms-hero-ecosystem__title span {
  display: block;
  transform: translateZ(rem(-$height-1));
}

.ui-ms-hero-ecosystem__subtitle {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-size-20);
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: rem($text-space-24);
  margin-top: rem($outer-space-30);
  max-width: 55%;
}

.ui-ms-hero-ecosystem__img {
  position: absolute;
  right: rem(-$andes-spacing-32);
  width: rem($width-450);
  z-index: 2;
}

.ui-ms-hero-ecosystem__wave {
  max-height: rem($height-500);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
