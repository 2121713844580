.tab-mobile {
  background: $color-light-grey;
  padding: rem($inner-space-45) 0 0;
}

.tab-mobile--white {
  background: $color-white;
}

.tab-mobile-title {
  color: $color-pink;
  font-family: $font-family-primary;
  font-size: rem($font-body-17);
  font-style: normal;
  font-weight: $font-weight-600;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($inner-space-28);
  margin-bottom: rem($inner-space-14);
  padding-left: 20%;
  padding: 0 rem($inner-space-25);
  text-transform: uppercase;
}

.tab-mobile-number-sub {
  align-items: start;
  display: flex;
  gap: rem($inner-space-14);
  padding: 0 rem($inner-space-25);
}

.tab-mobile-number {
  align-items: center;
  background: $color-pink;
  border-radius: $border-radius-100;
  color: $color-white;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-size-24);
  font-style: normal;
  font-weight: $font-weight-bold;
  height: rem($height-31);
  justify-content: center;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($inner-space-28);
  width: rem($width-31);
}

.tab-mobile-subtitle {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-title-34);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-4);
  line-height: rem($outer-space-38);
  margin-bottom: rem($outer-space-22);
  width: $width-75-percent;
}

.tab-mobile-button {
  align-items: center;
  border: none;
  border-top: rem($partners-border-secondary) solid $color-white;
  color: $color-pink;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-size-16);
  font-style: normal;
  font-weight: $font-weight-normal;
  gap: rem($outer-space-5);
  height: rem($height-53);
  justify-content: center;
  line-height: 120%;
  padding: 0 rem($inner-space-25);
  text-align: center;
  width: $width-100-percent;
}

.tab-mobile-button--white {
  background-color: $color-white;
  border-color: $color-light-grey;
}

.tab-mobile-button--active svg {
  transform: rotate(180deg);
}

.tab-mobile-body {
  padding: 0 rem($inner-space-25);
}

.tab-mobile-body-title {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-body-22);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-1);
  line-height: rem($inner-space-24);
}

.tab-mobile-body-subtitle {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-size-20);
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: rem($inner-space-24);
  margin-top: rem($outer-space-10);
}
