@media (width <= 1024px) {
  .connect-bread {
    font-size: rem($font-body-17);
    line-height: rem($font-body-17);
    padding: 0 rem($inner-space-25);
  }

  .connect-title {
    font-size: rem($font-title-34);
    line-height: rem($font-title-38);
    padding: 0 rem($inner-space-25);
    max-width: 100%;
  }

  .connect-subtitle {
    padding: 0 rem($inner-space-25);
    max-width: 100%;
    margin-bottom: rem($inner-space-60);
  }
}
