.agency-button {
  align-items: center;
  background: $color-pink;
  border-radius: rem($border-radius-5);
  border: none;
  color: $color-white;
  display: flex;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  height: rem($inner-space-44);
  justify-content: center;
  letter-spacing: rem($letter-spacing-3);
  line-height: rem($font-body-24);
  margin: 0 auto;
  margin-bottom: rem($inner-space-35);
  margin-top: rem($inner-space-70);
  max-width: rem($cont-351);
  text-decoration: none;
  width: 100%;
}

.agency-slide {
  margin: 0 auto;
  width: 90%;
}

.agency-slide__agency-slide-img-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.agency-slide__agency-card {
  align-items: center;
  background: $color-white;
  border-radius: rem($border-radius-8);
  box-shadow: 0 0 rem($inner-space-6) rgb(0 0 0 / 25%);
  display: flex;
  height: rem($cont-134);
  justify-content: space-between;
  max-width: rem($cont-160);
  padding: rem($andes-spacing-20);
}

.agency-slide__agency-slide-img {
  height: 100%;
  margin: 0 auto;
  max-height: rem($cont-80);
  object-fit: contain;
  width: 100%;
}

.agency-slide .slick-track {
  align-items: center;
  display: flex;
  gap: rem($inner-space-60);
  padding: rem($inner-space-10) 0;
}

.agency-slide .slick-dots li {
  height: rem($inner-space-8);
  width: rem($inner-space-8);
}

.agency-slide .slick-dots li button {
  height: rem($inner-space-8);
  width: rem($inner-space-8);
}

.agency-slide .slick-dots li button::before {
  border-radius: 100%;
  border: 1px solid $color-blue;
  font-size: 0;
  height: rem($inner-space-8);
  opacity: 1;
  width: rem($inner-space-8);
}

.agency-slide .slick-dots li.slick-active button::before {
  background: $color-blue;
  color: $color-blue;
}

.agency-slide .slick-dots {
  bottom: rem(-$inner-space-36);
}
