@media (max-width: 768px) {
  .bio-libre__title {
    font-size: rem($font-title-34);
    line-height: rem($font-title-38);
    margin-bottom: rem($andes-spacing-16);
  }

  .bio-libre__button-bio {
    display: block;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .bio-libre {
    margin: rem($inner-space-60 0 $inner-space-80);
    width: 100%;
  }

  .bio-libre__col-text {
    max-width: 100%;
  }

  .bio-libre__list {
    max-width: 100%;
  }

  .bio-libre__cont-bio {
    flex-direction: column; 
  }
  
  .bio-libre__description {
    margin: 0;
  }

  .bio-libre__image {
    display: block;
    margin: rem($andes-spacing-40) auto;
  }
}

@media (max-width: 1100px) {
  .bio-libre__cont-bio {
    padding: 0 rem($andes-spacing-20);
  }
}
