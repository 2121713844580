.container {
  max-width: rem($width-container);
  padding: 0 rem($andes-spacing-20);
  width: 100%;
}

.design {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  margin-top: rem($inner-space-24);
  position: relative;
  z-index: 10;
}

.design__title-cont {
  margin-bottom: rem($outer-space-150);
}

.design__headline {
  @include text-font(
    $colorTypo: $color-grey-text,
    $defaultWeight: $font-weight-regular,
    $lineHeight: $font-title-38,
    $size: $font-title-34
  );

  margin-bottom: rem($andes-spacing-24);
  text-align: left;
  width: 100%;
}

.design__headline--bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.design__paragraph {
  @include text-font;
  margin-bottom: rem($outer-space-38);
  text-align: left;
  width: 100%;
}

.design-cols-cont {
  align-items: center;
  background: $color-yellow-table;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.design-cols-cont__cont-text {
  background: $color-white;
  border-radius: rem(12px);
  box-shadow: rem(2px 4px 12px) rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: rem($outer-space-90);
  max-width: rem($cont-375);
  padding: rem($inner-space-35 $inner-space-25);
  width: 100%;
}

.design-cols-cont__icon-list {
  list-style-type: none;
}

.design-cols-cont__icon-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: rem($andes-spacing-32);
  width: 100%;
}

.design-cols-cont__icono {
  height: rem($icon-size-52);
  width: rem($icon-size-52);
}

.design-cols-cont__text-cont {
  max-width: rem($cont-250);
  padding-left: rem($andes-spacing-20);
  width: 100%;
}

.design-cols-cont__title {
  @include text-font(
    $colorTypo: $color-pink,
    $defaultWeight: $font-weight-bold,
    $lineHeight: $font-body-20,
    $size: $font-body-16
  );
}

.design-cols-cont__text {
  @include text-font($lineHeight: $font-body-20, $size: $font-body-16);
}

.design-cols-cont__cont-img {
  display: none;
  width: 70%;
}

.design-cols-cont__img-design {
  max-width: rem($cont-626);
  width: 100%;
}

.button-cont {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

.btn-mshops {
  @include text-font(
    $colorTypo: $color-white,
    $lineHeight: $font-body-18,
    $size: $font-body-18
  );

  background-color: $color-blue;
  border-radius: rem(4px);
  border: none;
  cursor: pointer;
  padding: rem($inner-space-10 $inner-space-15 $andes-spacing-12);
  text-decoration: none;
  width: 100%;
}

.btn-mshops:hover {
  background: $color-light-pink;
}
