// ----------------------------------
// OVERRIDES FOR THE HEADER COMPONENT
// ----------------------------------
header.nav-header {
  background-color: #fff;
}

header .nav-header-menu-wrapper [for="nav-header-menu-switch"],
header .nav-header-menu-wrapper .nav-header-user,
.nav-header-notifications-badge, // sass-lint:disable-line id-name-format
header #nav-header-menu .option-register, // sass-lint:disable-line id-name-format no-ids
header #nav-header-menu .option-help, // sass-lint:disable-line id-name-format no-ids
header #nav-header-menu .option-login { // sass-lint:disable-line id-name-format no-ids
  display: none;
}

header .nav-logo {
  background-image: $header-background-image-large !important;
  background-size: contain;
}

@media screen and (width <= 1023px) {
  header .nav-logo {
    background-image: $header-background-image-small !important;
  }
}

// ----------------------------------
// OVERRIDES FOR THE FOOTER COMPONENT
// ----------------------------------
footer .nav-footer-user.logged {
  display: none;
}

// ----------------------------------------
// OVERRIDES FOR THE DOWNLOAD APP COMPONENT
// ----------------------------------------
.nav-footer-downloadapp-banner,
.nav-footer-downloadapp-wrapper {
  display: none;
}

// ----------------------------------
// OVERRIDES FOR THE BUTTON COMPONENT
// ----------------------------------
.mercadoshops-button {
  &.andes-button--filled,
  &.andes-button--filled:link,
  &.andes-button--filled:visited,
  &.andes-button--filled:focus {
    background-color: $color-mshops;
    border-color: $color-mshops;
    color: $andes-white;
  }

  &.andes-button--filled:hover {
    background-color: $color-mshops-emphasis;
    border-color: $color-mshops-emphasis;
  }

  &.andes-button--outline,
  &.andes-button--outline:link,
  &.andes-button--outline:visited,
  &.andes-button--outline:focus {
    border-color: $andes-white;
    background-color: transparent;
    color: $andes-white;
    margin-bottom: rem(56px);
  }
}

// ----------------------------------
// OVERRIDES FOR NOT FOUND SCREEN
// ----------------------------------
.ui-empty-state__container {
  margin-top: rem($andes-spacing-64);
}

.andes-technical-error__container {
  margin-top: rem($andes-spacing-64);
  background-color: $andes-white;
  height: 80vh;
}
