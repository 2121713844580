.background-components {
  background: linear-gradient(
    30.8deg,
    $color-blue 15.99%,
    $color-purple 65.84%,
    $color-pink-gradient 91.22%
  );
}

.second-layer {
  background: $hero-partners;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 65%;
  padding-top: rem($inner-space-60);
}

.hero-partner__container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $width-container;
  min-height: 80vh;
}

.hero-partner__cont-text {
  width: 50%;
}

.hero-partner__title {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-title-70);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-3);
  line-height: rem($line-height-65);
  margin-bottom: rem($inner-space-24);
}

.hero-partner__subtitle {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: rem($text-space-24);
}

.hero-partner__image-hero {
  max-width: rem($image-hero-partners);
  width: 100%;
}
