.ecosystems {
  width: 100%;
}

.ecosystems__bg-grey {
  background-color: $color-light-grey;
  margin-top: rem(-$inner-space-6);
  position: relative;
  z-index: 2;
}

.ecosystems__bg-grey-last {
  background-color: $color-light-grey;
  padding: rem($inner-space-100) 0;
}

.ecosystems__bg-white-first {
  background-color: $color-white;
  padding: rem($inner-space-80) 0 rem($andes-spacing-40);
}

.ecosystems__bg-white {
  background-color: $color-white;
  padding: rem($andes-spacing-24) 0;
}

.ecosystems__shape-top {
  background: $color-white;
  font-size: 0;
}

.ecosystems__shape-bottom {
  background-color: $color-light-grey;
  font-size: 0;
  margin-top: rem(-$andes-spacing-20);
  position: relative;
  z-index: 0;
}

.ecosystems__container {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: rem($width-container);
  width: 100%;
}

.ecosystems__container--left-to-right {
  flex-direction: row;
}

.ecosystems__container--right-to-left {
  flex-direction: row-reverse;
}

.ecosystems__col-image,
.ecosystems__col-text {
  width: 50%;
}

.ecosystems__image {
  max-width: rem($img-size-530);
  width: 100%;
}

.ecosystems__col-image--image-space-ltr {
  padding-left: rem($andes-spacing-40);
}

.ecosystems__col-image--image-space-rtl {
  padding-right: rem($andes-spacing-40);
}

.ecosystems__image {
  max-width: rem($img-size-530);
  width: 100%;
}

.ecosystems__logo {
  align-self: flex-start;
  max-width: rem($inner-space-150);
  width: 100%;
}

.ecosystems__title {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-title-45);
  line-height: rem($font-title-46);
  margin: rem($andes-spacing-24) 0;
}

.icon-list__item-list {
  align-items: center;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: rem($inner-space-28);
}

.icon-list__icon {
  align-items: center;
  border-radius: 50%;
  border: rem($border-radius-1-5) solid $color-magent-icons;
  display: flex;
  height: rem($inner-space-36);
  justify-content: center;
  margin-right: rem($andes-spacing-20);
  width: rem($inner-space-36);
}

.icon-list__text {
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  max-width: 80%;
}

.btn-ecosystem {
  background-color: $color-pink;
  border-radius: rem($inner-space-4);
  border: none;
  color: $color-white;
  cursor: pointer;
  font-size: rem($font-body-18);
  line-height: rem($font-body-18);
  margin-top: rem($andes-spacing-20);
}

.btn-ecosystem:hover {
  background: $color-light-pink;
}
