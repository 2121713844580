.unavailable__error-screen {
  background-color: $color-gray-lightest;
  text-align: center;
}

.error-screen__message {
  background-color: $andes-success-green;
  color: $andes-white;
  font-size: $font-size-18;
  font-weight: $font-weight-semibold;
  padding: rem(32px) 0;
}

.error-screen__container {
  margin: 0 auto;
  padding: rem(24px) rem(24px) rem(48px) rem(24px);
}

.error-screen__title {
  font-size: $font-size-18;
  font-weight: $font-weight-semibold;
  line-height: $error-screen-text-line-height;
  padding: rem(32px) rem(16px) rem(20px) rem(16px);
}

.error-screen__icon .icon__computer-nav {
  fill: $color-mshops;
}

.error-screen__text {
  margin-bottom: rem(32px);

  p {
    font-size: $font-size-16;
  }

  p:first-child {
    margin-bottom: rem(20px);
  }
}
