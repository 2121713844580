@media (width <= 1100px) {
  .ecosystems__container {
    padding: rem($andes-spacing-20);
  }
}

@media (width <= 1024px) {
  .ecosystems__container {
    flex-direction: column;
  }

  .ecosystems__col-image,
  .ecosystems__col-text {
    max-width: rem($cont-540);
    width: 100%;
  }

  .ecosystems__shape-bottom {
    margin-top: 0;
  }

  .ecosystems__bg-grey-last {
    padding: rem($andes-spacing-40) 0;
  }

  .ecosystems__col-text {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .ecosystems__title {
    font-size: rem($font-title-34);
    line-height: rem($font-title-38);
    margin: rem($andes-spacing-20) 0 rem($andes-spacing-24);
  }

  .icon-list {
    margin-top: rem($inner-space-45);
  }
}
