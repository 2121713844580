@media (width >= 768px) {
  .about-ms__headline {
    font-family: $font-family-primary;
    font-size: rem($font-title-45);
    line-height: rem($font-title-46);
    margin-bottom: rem($andes-spacing-24);
  }

  .about-ms__copy-text {
    margin-bottom: rem($andes-spacing-24);
  }
}

@media (width >= 1024px) {
  .about-ms {
    margin-bottom: rem($inner-space-60);
    margin-top: rem($outer-space-82);
    padding: 0;
  }

  .about-ms__container {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 rem($andes-spacing-40);
  }

  .about-ms__cont-text {
    width: 45%;
  }

  .about-ms__cont-img {
    text-align: left;
    width: 50%;
  }

  .about-ms__img-desktop {
    display: initial;
  }
}

@media (width >= 1100px) {
  .about-ms__container {
    padding: 0;
  }
}
