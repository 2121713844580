.brands {
  background: $color-yellow-table;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: rem($margin-space-120);
  padding-bottom: rem($inner-space-150);
  padding-top: rem($inner-space-72);
  position: relative;
  width: 100%;
  z-index: 1;
}

.brands__container {
  max-width: rem($width-container);
  width: 100%;
}

.brands__cont-title {
  margin-bottom: rem($andes-spacing-40);
}

.brands__title {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-title-30);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-space);
  line-height: rem($font-title-34);
  text-align: center;
}

.brands__title--bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.cards-brands {
  width: 100%;
}

.cards-brands__cards-list {
  list-style-type: none;
}

.image-modal-thumbnail {
  border-radius: none;
  height: auto;
  max-width: rem($width-container-600);
  width: 100%;
}
