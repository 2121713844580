@media (width <= 1024px) {
  .agency-button {
    font-size: rem($font-body-16);
    max-width: calc(100% - rem($inner-space-50));
  }

  .agency-slide {
    width: 100%;
  }

  .agency-slide__agency-card {
    margin: 0 auto;
    max-width: rem($cont-242);
    padding: rem($inner-space-28);
  }

  .agency-slide .slick-track {
    gap: rem($inner-space-15);
  }

  .agency-slide .slick-dots li button::before {
    border-color: $color-white;
  }

  .agency-slide .slick-dots li.slick-active button::before {
    background: $color-white;
    color: $color-white;
  }
}

@media (width <= 480px) {
  .agency-slide__agency-card {
    margin: 0;
  }
}
