.rockets__title {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-title-45);
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-space);
  line-height: rem($font-title-46);
  margin-bottom: rem($inner-space-35);
  margin: 0 auto;
  max-width: rem($cont-540);
  text-align: center;
}

.rockets__card-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: rem($andes-spacing-20);
  width: 100%;
}

.rockets__card-container::-webkit-scrollbar {
  display: none;
}

.rockets__card {
  border-radius: rem($andes-spacing-20);
  margin-bottom: rem($andes-spacing-40);
  margin: 0 auto;
  width: rem($width-container);
}

.rockets__card--card-mlb {
  width: rem($width-container);
}

.rockets__steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  margin: 0 auto;
  max-width: rem($width-container);
  padding-bottom: rem($inner-space-28);
}

.rockets__steps--cont-mlb {
  grid-template-columns: 1fr 1fr;
  margin-top: rem($andes-spacing-20);
  max-width: rem($cont-822);
}

.rockets__lines {
  position: relative;
}

.rockets__img {
  display: flex;
  margin: 0 auto;
  max-width: rem($width-container);
  position: relative;
  width: 100%;
  z-index: 2;
}

.rockets__rocket-head {
  align-items: center;
  display: flex;
  gap: rem($inner-space-7);
  justify-content: start;
}

.rockets__rocket-number {
  align-items: center;
  background: $color-pink;
  border-radius: 100%;
  color: $color-blue;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-body-24);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  height: rem($andes-spacing-32);
  justify-content: center;
  letter-spacing: rem($letter-space);
  line-height: rem($font-body-28);
  width: rem($andes-spacing-32);
}

.rockets__rocket-head-title {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-title-34);
  font-style: normal;
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-space);
  line-height: rem($font-title-38);
}

.rockets__rocket-subtitle {
  color: $color-pink;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-weight: $font-weight-bold;
  line-height: rem($font-body-20);
  margin-bottom: rem($inner-space-10);
  margin-top: rem($inner-space-20);
}

.rockets__rocket-description {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  margin-bottom: rem($inner-space-25);
  max-width: rem($cont-300);
  min-height: rem($inner-space-100);
}

.rockets__rocket-line {
  background-color: $ilustration-grey;
  height: rem($inner-space-3);
  position: relative;
  width: 100%;
}

.rockets__rocket-line-dot {
  background: $color-pink;
  border-radius: 100%;
  display: block;
  height: rem($inner-space-15);
  left: rem(-$inner-space-3);
  position: absolute;
  top: rem(-$inner-space-6);
  width: rem($inner-space-15);
}

.rockets__arrow {
  position: absolute;
  right: rem(-$inner-space-4);
  top: rem(-$inner-space-8);
}

.rockets__rocket-months {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.rockets__rocket-month {
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-space);
  line-height: rem($font-body-17);
  margin-top: rem($andes-spacing-32);
}
