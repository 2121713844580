.about-ms {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0;
  padding: rem(44px 20px 60px);
  position: relative;
  z-index: 10;
}

.about-ms__container {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  max-width: rem($width-container);
  width: 100%;
}

.about-ms__cont-text {
  width: 100%;
}

.about-ms__headline {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-title-34);
  font-weight: $font-weight-regular;
  letter-spacing: rem(-0.48px);
  line-height: rem($font-title-38);
  margin-bottom: rem($andes-spacing-20);
}

.about-ms__headline-bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.about-ms__copy-text {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-24);
  margin-bottom: rem($andes-spacing-20);
}

.about-ms__copy-text--bold {
  font-weight: $font-weight-600;
}

.about-ms__cont-img {
  text-align: center;
  width: 100%;
}

.about-ms__img-desktop {
  display: none;
  max-width: rem($img-cont-459);
  width: 100%;
}

.item-list {
  align-items: start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: rem($andes-spacing-24);
  width: 100%;
}

.item-list__icon {
  height: rem($inner-space-34);
  width: rem($inner-space-34);
}

.item-list__text-cont {
  padding-left: rem($andes-spacing-20);
  width: 100%;
}

.item-list__text {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
}
