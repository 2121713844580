@import '~@andes/common/index';

html {
  font-size: $font-body-13 !important;
}

body {
  background: $color-white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
