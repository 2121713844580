@media (width <= 1024px) {
  .studio-button {
    font-size: rem($font-body-16);
    max-width: calc(100% - rem($inner-space-50));
  }

  .studio-cont__photograph-card-content {
    text-align: center;
  }

  .studio-cont__photograph-card {
    flex-direction: column;
    height: rem($inner-space-165);
  }

  .studio-cont__photograph-slide-img {
    height: rem($inner-space-100);
    margin-right: 0;
    width: rem($inner-space-100);
  }

  .studio-cont__photograph-card-title {
    text-align: center;
  }
}

@media (width <= 400px) {
  .studio-cont__photograph-card-title {
    height: rem($inner-space-96);
  }

  .studio-cont__photograph-card {
    height: rem($inner-space-212);
  }
}
