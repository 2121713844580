.ebooks-gallery {
  width: 100%;
}

.ebooks-gallery__gallery-cont {
  margin: rem($andes-spacing-40) auto;
  max-width: rem($width-container);
  width: 100%;
}

.ebooks-gallery__gallery-cont:first-child {
  margin-top: 0;
}

.ebooks-gallery__gallery-cont-title {
  align-items: center;
  border-bottom: rem($border-size-2) solid $color-light-grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: rem($andes-spacing-20);
  padding-bottom: rem($andes-spacing-20);
}

.ebooks-gallery__gallery-title {
  border-radius: rem($border-radius-8);
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-spacing-7);
  line-height: rem($font-body-24);
  padding: rem($andes-spacing-8);
  text-transform: uppercase;
}

.title-icon {
  align-items: center;
  border-radius: 50%;
  border: rem($border-size-1) solid $color-blue;
  display: flex;
  height: rem($inner-space-24);
  justify-content: center;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: rem($inner-space-24);
}

.title-icon--up {
  rotate: -180deg;
  transition: all 0.3s ease-in-out;
}

.ebooks-gallery__gallery-title--custom {
  background: $color-category-custom;
}

.ebooks-gallery__gallery-title--sells {
  background: $color-category-sells;
}

.ebooks-gallery__gallery-title--trafic {
  background: $color-category-trafic;
}

.ebooks-gallery__gallery-title--config {
  background: $color-category-config;
}

.ebooks-gallery__gallery-ebooks {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
}

.ebooks-gallery__ebook-card {
  padding: rem($andes-spacing-20) 0;
  width: 33%;
}

.ebooks-gallery__image-cont {
  background: linear-gradient(
    360deg,
    $color-grey-gradient 0%,
    $color-white 61.56%
  );
  border-bottom: rem($border-size-3) solid $bg-table-col5;
  font-size: 0;
  text-align: center;
}

.ebooks-gallery__image {
  border-radius: rem($border-radius-5);
  max-width: rem($image-ebook);
  min-height: rem($inner-space-207);
  width: 100%;
}

.ebooks-gallery__text-cont {
  min-height: rem($cont-110);
  padding: 0 rem($andes-spacing-20);
  text-align: center;
  width: 100%;
}

.ebooks-gallery__text {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  margin: rem($andes-spacing-20) 0;
  min-height: rem($inner-space-40);
  text-align: center;
}

.ebooks-gallery__button {
  background: $color-blue;
  border-radius: rem($border-radius-4);
  box-sizing: border-box;
  color: $color-white;
  font-family: $font-family-primary;
  font-size: rem($font-body-14);
  font-weight: $font-weight-regular;
  padding: rem($andes-spacing-8 $andes-spacing-24);
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
