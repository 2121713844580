@media (width <= 1024px) {
  .tabs__card {
    background: none;
    border: none;
    margin-bottom: rem($outer-space-30);
    padding: 0;
  }

  .tabs__card-head {
    flex-direction: column;
  }

  .tabs__card-content {
    width: $width-100-percent;
  }

  .tabs__card-title {
    display: none;
  }

  .tabs__card-video {
    display: none;
  }

  .tabs__card-footer {
    flex-direction: column;
    position: relative;
  }

  .tabs__card-count {
    background: $color-blue;
    border-radius: rem($border-radius-14);
    box-shadow: rem(0px 4px 4px) rgb(0 0 0 / 25%);
    padding: rem($inner-space-20);
    width: $width-100-percent;
  }

  .tabs__card-count-divider {
    background-color: $color-white;
    height: $tabs-card-count-divider-height-mobile;
    left: $width-50-percent;
    position: absolute;
  }

  .tabs__card-count-icon-number {
    align-items: start;
    flex-direction: column;
    gap: rem($inner-space-8);
  }

  .tabs__card-count-item-number {
    color: $color-white;
  }

  .tabs__card-count-item-title {
    color: $color-white;
  }

  .tabs__card-count-item-subtitle {
    color: $color-white;
    width: $width-95-percent;
  }

  .tabs__card-experience {
    color: $color-blue;
    display: flex;
    font-family: $font-family-primary;
    font-size: $font-size-20;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: rem($text-space-24);
    padding: rem($inner-space-24) 0;
  }

  .tabs__card-experience-video {
    color: $color-blue;
    display: flex;
    font-family: $font-family-primary;
    font-size: $font-size-20;
    font-style: normal;
    font-weight: $font-weight-bold;
    line-height: rem($text-space-24);
    margin-bottom: rem($outer-space-25);
    width: $width-100-percent;
  }

  .tabs__card-experience-video iframe {
    height: auto;
    width: $width-100-percent;
  }

  .tabs__card-alert {
    background: none;
    box-shadow: none;
    padding: 0;
    width: $width-100-percent;
  }

  .tabs__card-alert-title {
    border-radius: rem($border-radius-8);
    border: rem($partners-border-secondary) solid $color-pink;
    font-family: $font-family-primary;
    font-size: rem($font-size-20);
    line-height: rem($text-space-24);
    padding: $inner-space-35;
  }

  .tabs__card-alert-title--notborder {
    border: none;
    padding: 0;
  }

  .tabs_card-message {
    position: relative;
  }

  .tabs_card-message-rectangle {
    background: $color-white;
    bottom: rem($inner-space-24);
    display: initial;
    height: rem($height-2);
    left: $width-50-percent;
    position: absolute;
    transform: translateX(-$width-50-percent);
    width: $inner-space-20;
    z-index: 1;
  }

  .tabs__card-alert-triangle {
    display: initial;
    position: relative;
    top: rem(-$inner-space-8);
  }

  .tabs__card-alert-button {
    margin-top: rem($outer-space-20);
  }

  .tabs__card-advice {
    margin-top: rem($outer-space-30);
  }

  .tabs__card-advice-padding {
    padding: rem(50px 27px 30px);
  }

  .tabs__card-advice-title {
    font-family: $font-family-primary;
    font-size: $font-size-24;
    letter-spacing: rem($letter-spacing-4);
    line-height: rem($inner-space-28);
    margin-bottom: rem($outer-space-15);
  }

  .tabs__card-advice-subtitle {
    line-height: 120%;
  }

  .tabs__card-advice-headset {
    height: rem($width-45);
    padding: rem($inner-space-10);
    width: rem($height-45);
  }

  .tabs__card-advice-headset img {
    height: rem($width-30);
    width: rem($height-30);
  }

  .tabs__card-slide-footer {
    margin-top: rem($outer-space-30);
  }

  .tabs__card-slide-title {
    font-family: $font-family-primary;
    font-size: $font-size-20;
    font-weight: $font-weight-bold;
    width: $width-60-percent;
  }

  .tabs__card-slide-button {
    font-family: $font-family-primary;
    font-size: rem($font-size-16);
    max-width: $width-100-percent;
  }

  .tabs__card-slide-img {
    width: $width-70-percent;
  }

  .tabs__card-slide-arrows {
    display: none;
  }
}
