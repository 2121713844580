@media (width <= 1024px) {
  .ui-ms-hero-ecosystem__container {
    flex-direction: column;
    padding: 0 rem($inner-space-25);
    position: relative;
  }

  .ui-ms-hero-ecosystem__content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .ui-ms-hero-ecosystem__title {
    font-size: rem($font-size-44);
    font-weight: $font-weight-extrabold;
    letter-spacing: rem($letter-spacing-6);
    line-height: rem($inner-space-45);
    max-width: rem($cont-540);
    width: 100%;
  }

  .ui-ms-hero-ecosystem__title span {
    display: inline;
  }

  .ui-ms-hero-ecosystem__subtitle {
    margin-top: rem($outer-space-20);
    max-width: rem($cont-540);
    width: 100%;
  }

  .ui-ms-hero-ecosystem__subtitle br {
    display: none;
  }

  .ui-ms-hero-ecosystem__img {
    max-width: rem($width-450);
    position: initial;
    width: $width-100-percent;
  }

  .ui-ms-hero-ecosystem__wave {
    display: none;
  }
}

@media (width <= 1100px) {
  .ui-ms-hero-ecosystem__container {
    padding: 0 rem($andes-spacing-20);
  }
}
