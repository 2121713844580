.ecosystems-cards {
  display: block;
  margin: 0 rem($inner-space-6);
  padding-bottom: rem($andes-spacing-20);
}

.ecosystems-cards__card-cont {
  align-items: flex-start;
  background: $color-white;
  border-radius: rem($inner-space-8);
  border: 1px solid $color-grey-d9;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column; 
  justify-content: center;
  padding: rem($andes-spacing-40) rem($andes-spacing-12) rem($andes-spacing-20) rem($inner-space-10);
  position: relative;
  transition: 1s;
}

.ecosystems-cards__logo {
  height: rem($inner-space-40);
  margin-bottom: rem($andes-spacing-24);
}

.ecosystems-cards__logo-mlb {
  max-width: rem($inner-space-120);
}

.ecosystems-cards__content {
  min-height: rem($inner-space-206);
}

.ecosystems-cards__title {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-body-24);
  font-style: normal;
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-spacing-6);
  line-height: rem($font-body-28);
  margin-bottom: rem($andes-spacing-20);
}

.ecosystems-cards__text {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-body-16);
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
}

.meli-border::after {
  background: $color-yellow;
  border-radius: 0px 0 rem($inner-space-8) rem($inner-space-8);
  bottom: rem(-$inner-space-11);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  content: '';
  height: rem($inner-space-15);
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.cian-border::after {
  background: $color-cian;
  border-radius: 0px 0 rem($inner-space-8) rem($inner-space-8);
  bottom: rem(-$inner-space-11);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  content: '';
  height: rem($inner-space-15);
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}
