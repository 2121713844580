$andes-theme: 'mercadolibre';
@import '~@andes/common/index';

/* Mercado Shops logo */
$header-background-image-small: url('../../../assets/images/logo-handshake-small.svg');
$header-background-image-large: url('../../../assets/images/logo-handshake-large.svg');

/* Social Networks logos */
$facebook-logo: url('../../../assets/images/facebook_circulo1.svg');
$facebook-logo-hover: url('../../../assets/images/facebook_circulo2.svg');
$instagram-logo: url('../../../assets/images/instagram_circulo1.svg');
$instagram-logo-hover: url('../../../assets/images/instagram_circulo2.svg');
$youtube-logo: url('../../../assets/images/youtube_circulo1.svg');
$youtube-logo-hover: url('../../../assets/images/youtube_circulo2.svg');
$linkedin-logo: url('../../../assets/images/linkedin_circulo1.svg');
$linkedin-logo-hover: url('../../../assets/images/linkedin_circulo2.svg');

//bg-image
$hero-partners: url('../assets/images/bg_hero_partners.svg');

//Wrapper
$width-container: 1100px;
$width-container-600: 600px;
$ecosystem-card-content: 384px;
$ecosystem-card-cont: 237px;
$ecosystem-card-cont-mobile: 283px;
$card-height: 400px;
$card-height-300: 300px;
$img-cont-459: 459px;
$image-mobile-hero: 594px;
$image-hero-partners: 536px;
$bg-wave-height: 60px;
$bg-wave-width: 1920px;
$table-expand: 1600px;
$table-expand-legal: 2000px;
$bg-wave-top-position: -157px;
$bg-wave-98: 98px;
$bg-wave-120: 120px;
$bg-wave-174: 174px;
$bg-wave-240: 240px;
$cont-80: 80px;
$cont-626: 626px;
$cont-660: 660px;
$cont-250: 250px;
$cont-351: 351px;
$cont-300: 300px;
$cont-333: 333px;
$cont-375: 375px;
$cont-540: 540px;
$cont-785: 785px;
$cont-12: 12px;
$cont-8: 8px;
$cont-160: 160px;
$cont-130: 130px;
$cont-134: 134px;
$cont-242: 242px;
$cont-490: 490px;
$cont-743: 743px;
$cont-822: 822px;
$cont-110: 110px;
$cont-310: 310px;
$cont-290: 290px;
$accordion-height: 9999px;
$chevron-size: 24px;
$img-cont-459: 459px;
$img-size-648: 648px;
$img-size-530: 530px;
$icon-size-52: 52px;
$image-ebook: 146px;

/* Colors */
$color-grey: #333;
$color-grey-d9: #d9d9d9;
$color-grey-7c: #7c7c7c;
$color-gray-lightest: #f9f9f9;
$color-white: #fff;
$hero-gray: #ececec;
$color-blue: #2D3277;
$color-purple: #6c2590;
$color-purple-hero: #7d2692;
$color-blue-hover: #0d00a7;
$color-blue-rgb: rgb(10 0 128 / 25%);
$color-pink: #e73b90;
$color-magent: #fd51a6;
$color-magent-icons: #e43b8d;
$color-pink-gradient: #fa5ba7;
$color-pink-hover: #fc48a1;
$color-magent-card: #fb51a4;
$color-violet: #a984ff;
$color-magent: #fd51a6;
$color-green: #00c69f;
$color-yellow-table: #FFE600;
$color-yellow-home: #FFF48E;
$color-yellow-home-gradient: #FFD100;
$color-light-green: #a8d91e;
$color-light-pink: #fc48a1;
$color-light-grey: #eee;
$color-grey-text: #0c0c0c;
$color-grey-table: #525252;
$color-grey-table-cols: #3a3a3a;
$color-grey-hover: #e0e0e0;
$color-yellow: #ffcf00;
$color-yellow-card: #ffe600;
$color-cian: #00bcff;
$color-ecosystem-slide-active: rgb(255 207 0 / 100%);
$color-ecosystem-slide-item: rgb(255 207 0 / 40%);
$bg-table-head: #f7f7f7;
$bg-table-col3: #e4e4e4;
$bg-table-col4: #cacaca;
$bg-table-col5: #a2a2a2;
$ilustration-grey: #b2c2d0;
$color-grey-gradient: #ebebeb;
$color-gray-pricing: #4d4d4d;
$color-pink-pricing: #e63c90;
$color-category-custom: #dfc0b4;
$color-category-sells: #ede4ce;
$color-category-trafic: #cfc4c5;
$color-category-config: #b2c2d0;
$transparent: rgba(#fff, 0);
$font-weight-600: 600;
$border-radius-100: 100%;
$border-radius-20: 20px;
$border-radius-14: 14px;
$border-radius-18: 18px;
$border-radius-12: 12px;
$border-radius-4: 4px;
$border-radius-5: 5px;
$border-radius-8: 8px;
$border-radius-1-5: 1.5px;
$border-size-3: 3px;
$border-size-2: 2px;
$border-size-1: 1px;

//Spaces
$inner-space-3: 3px;
$inner-space-4: 4px;
$inner-space-6: 6px;
$inner-space-7: 7px;
$inner-space-8: 8px;
$inner-space-10: 10px;
$inner-space-11: 11px;
$inner-space-12: 12px;
$inner-space-13: 13px;
$inner-space-14: 14px;
$inner-space-15: 15px;
$inner-space-16: 16px;
$inner-space-18: 18px;
$inner-space-20: 20px;
$inner-space-24: 24px;
$inner-space-25: 25px;
$inner-space-28: 28px;
$inner-space-30: 30px;
$inner-space-33: 33px;
$inner-space-35: 35px;
$inner-space-34: 34px;
$inner-space-36: 36px;
$inner-space-37: 37px;
$inner-space-40: 40px;
$inner-space-44: 44px;
$inner-space-45: 45px;
$inner-space-50: 50px;
$inner-space-54: 54px;
$inner-space-60: 60px;
$inner-space-66: 66px;
$inner-space-70: 70px;
$inner-space-72: 72px;
$inner-space-96: 96px;
$inner-space-75: 75px;
$inner-space-80: 80px;
$inner-space-100: 100px;
$inner-space-110: 110px;
$inner-space-120: 120px;
$inner-space-150: 150px;
$inner-space-165: 165px;
$inner-space-180: 180px;
$inner-space-206: 206px;
$inner-space-207: 207px;
$inner-space-210: 210px;
$inner-space-212: 212px;
$inner-space-250: 250px;
$inner-space-380: 380px;
$inner-space-485: 485px;
$inner-space-500: 500px;
$inner-space-510: 510px;
$fix-margin-100: -100px;
$ecosystem-card-cont-padding: 22px 20px 28px 20px;
$ecosystem-top: -19px;
$ecosystem-dot-height: 6px;
$ecosystem-dot-width: 40px;
$ecosystem-headline-letter-spacing: -0.13px;
$ecosystem-headline-line-height: 46px;
$ecosystem-headline-letter-spacing-mobile: -0.39px;
$ecosystem-headline-line-height-mobile: 38px;
$ecosystem-ballons-height: 575px;
$ecosystem-ballons-width: 632px;
$ecosystem-ballon: 95px;
$ecosystem-ballon-meli-top: 40px;
$ecosystem-ballon-meli-left: 220px;
$ecosystem-ballon-mp-top: 160px;
$ecosystem-ballon-mp-left: 420px;
$ecosystem-ballon-mads-top: 390px;
$ecosystem-ballon-mads-left: 390px;
$ecosystem-ballon-mcred-top: 420px;
$ecosystem-ballon-mcred-left: 140px;
$ecosystem-ballon-menv-top: 260px;
$ecosystem-ballon-menv-left: 60px;
$ecosystem-logo-ballon-img: 60px;
$ecosystem-card-border-radius: 9px;
$ecosystem-dots-border-radius: 10px;
$ecosystem-dots-slider-margin-top: -65px;
$ecosystem-slick-active-bottom: 15px;
$ecosystem-slick-active-height: 5px;
$ecosystem-dots-slider-li-width: 40px;
$ecosystem-dots-slider-item-height: 5px;
$ecosystem-card-cont-img-height: 31px;
$ecosystem-dots-slider-top: -10px;
$ecosystem-dots-slider: 8px;
$ecosystem-slick-slide-space: 0 6.5px;
$ecosystem-slick-slide-space-desktop: 0 2px;
$pricing-mobile-btn-group-height: 39px;
$pricing-mobile-btn-img-height: 15px;
$pricing-mobile-table-max-height: 263px;
$pricing-mobile-table-thead-height: 43px;
$pricing-mobile-border: 8px;
$pricing-mobile-first-ms-span-height: 22px;
$pricing-mobile-first-ms-span-width: 1px;
$pricing-mobile-first-ms-span-right: -2px;
$pricing-mobile-table-tbody-tr-height: 73px;
$pricing-mobile-border-top: 3px;
$pricing-mobile-main-line-height: 18px;
$pricing-mobile-main-span-width: 80%;
$pricing-mobile-main-span-bottom: -2px;
$pricing-mobile-main-span-height: 1px;
$pricing-mobile-border-other-line-height: 13px;
$pricing-mobile-border-other-color: #575757;
$pricing-mobile-left-line-height: 10px;
$pricing-mobile-show-info-gap: 11px;
$pricing-mobile-show-info-line-height: 13px;

//margins
$outer-space-20: 20px;
$outer-space-30: 30px;
$outer-space-32: 32px;
$outer-space-38: 38px;
$outer-space-36: 36px;
$outer-space-40: 40px;
$outer-space-65: 65px;
$outer-space-5: 5px;
$outer-space-8: 8px;
$outer-space-10: 10px;
$outer-space-15: 15px;
$outer-space-22: 22px;
$outer-space-24: 24px;
$outer-space-26: 26px;
$outer-space-34: 34px;
$outer-space-45: 45px;
$outer-space-50: 50px;
$outer-space-28: 28px;
$outer-space-25: 25px;
$outer-space-62: 62px;
$outer-space-48: 48px;
$outer-space-31: 31px;
$outer-space-70: 70px;
$outer-space-57: 57px;

//text-margins
$text-space-20: 20px;
$text-space-24: 24px;
$text-space-30: 30px;
$text-space-32: 32px;

//margins
$margin-space-12: 12px;
$margin-space-40: 40px;
$outer-space-82: 82px;
$outer-space-90: -90px;
$outer-space-125: -125px;
$outer-space-150: 150px;
$outer-space-100: -100px;
$margin-space-2: 2px;
$margin-space-5: 5px;
$margin-space-50: 50px;
$margin-space-120: 120px;
$margin-space-160: -160px;
$top-margin: 82px;
$border-radius-15: 15px;
$line-height-65: 65px;

//positions
$bottom-60: -60px;

/* Font sizes */
$font-body-11: 11px;
$font-body-12: 12px;
$font-body-13: 13px;
$font-body-14: 14px;
$font-body-15: 15px;
$font-body-16: 16px;
$font-body-17: 17px;
$font-body-18: 18px;
$font-body-20: 20px;
$font-body-22: 22px;
$font-body-24: 24px;
$font-body-28: 28px;
$font-body-35: 35px;
$font-title-30: 30px;
$font-title-32: 32px;
$font-title-34: 34px;
$font-title-38: 38px;
$font-title-40: 40px;
$font-title-42: 42px;
$font-title-44: 44px;
$font-title-45: 45px;
$font-title-46: 46px;
$font-title-65: 65px;
$font-title-70: 70px;
$font-weight-extrabold: 800;
$font-weight-600: 600;
$font-weight-bold: 700;
$font-weight-medium: 500;
$error-screen-text-line-height: 1.2;
$letter-space: -0.13px;
$letter-spacing-1: -0.13px;
$letter-spacing-2: 1.95px;
$letter-spacing-3: 0.13px;
$letter-spacing-4: -0.39px;
$letter-spacing-5: -0.26px;
$letter-spacing-6: -0.65px;
$letter-spacing-7: -0.32px;
$letter-spacing-8: 1.4px;
$height-50-percent: 50%;
$height-100-percent: 100%;
$height-1: 1px;
$height-2: 2px;
$height-8: 8px;
$height-30: 30px;
$height-31: 31px;
$height-45: 45px;
$height-53: 53px;
$height-55: 55px;
$height-80: 80px;
$height-300: 300px;
$width-33-percent: 33.33%;
$width-50-percent: 50%;
$width-60-percent: 60%;
$width-70-percent: 70%;
$width-75-percent: 75%;
$width-95-percent: 95%;
$width-100-percent: 100%;
$width-8: 8px;
$width-30: 30px;
$width-31: 31px;
$width-45: 45px;
$width-55: 55px;
$width-70: 70px;
$width-326: 326px;
$width-408: 408px;
$width-450: 450px;
$height-500: 500px;
$partners-title-line-height: 46px;
$partners-border: 8px;
$partners-border-secondary: 1px;
$partners-border-color: #bbb;
$partners-btn-group-height: 51px;
$partners-btn-width: 149px;
$partners-btn-tab-line-height: 22px;
$partners-btn-active: rgb(231 59 144 / 10%);
$partners-content-border: 18px;
$partners-content-height: 439px;
$partners-info-padding: 64px 30px 58px 60px;
$partners-description-line-height: 24px;
$partners-item-line-height: 20px;
$partners-btn-tab-border: 5px;
$partners-btn-tab-heigth: 44px;
$partners-btn-tab-width: 175px;
$partners-tab-background-height: 250px;
$partners-info-padding-mobile: 11px 24px 19px;
$partners-btn-group-height-mobile: 38px;
$tabs-btn-group-height: 51px;
$tabs-btn-gap: 9px;
$tabs-btn-span-width: 20px;
$tabs-btn-span-height: 20px;
$tabs-card-background: linear-gradient(180deg, #ebebeb -8.04%, #fff 93.21%);
$tabs-card-padding: 50px 64px 62px;
$tabs-card-item-gap: 13px;
$tabs-card-count-gap: 30px;
$tabs-card-count-divider-height: 110px;
$tabs-card-count-divider-height-mobile: 170px;

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.eot');
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.eot?#iefix')
      format('embedded-opentype'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-500.eot');
  src: url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-500.eot?#iefix')
      format('embedded-opentype'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-500.woff2')
      format('woff2'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-500.woff')
      format('woff'),
    url('https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-500.ttf')
      format('truetype');
}

@mixin text-font(
  $colorTypo: $color-grey-text,
  $defaultFont: $font-family-primary,
  $defaultWeight: $font-weight-regular,
  $lineHeight: $font-body-24,
  $size: $font-body-20,
  $spacing: $letter-space
) {
  color: $colorTypo;
  font-family: $defaultFont;
  font-size: rem($size);
  font-weight: $defaultWeight;
  letter-spacing: rem($spacing);
  line-height: rem($lineHeight);
}

$background-ecosystem: url('../assets/images/ecosystem-background.png');
$cases-mla-1: url('../assets/images/mla/marcas_01.png') no-repeat center right;
$cases-mla-2: url('../assets/images/mla/marcas_02.png') no-repeat center right;
$cases-mla-3: url('../assets/images/mla/marcas_03.png') no-repeat center right;
$cases-mla-4: url('../assets/images/mla/marcas_04.png') no-repeat center right;
$cases-mlc-1: url('../assets/images/mlc/bg_eforest.png') no-repeat center right;
$cases-mlc-2: url('../assets/images/mlc/bg_happy.png') no-repeat center right;
$cases-mlc-3: url('../assets/images/mlc/bg_lhua.png') no-repeat center right;
$cases-mco-1: url('../assets/images/mco/bg_tecnoplaza.png') no-repeat center
  right;
$cases-mco-2: url('../assets/images/mco/bg_celulares99.png') no-repeat center
  right;
$cases-mco-3: url('../assets/images/mco/bg_bebesitos.png') no-repeat center
  right;
$cases-mco-4: url('../assets/images/mco/bg_croydon.png') no-repeat center right;
$cases-mlm-1: url('../assets/images/mlm/bg_friso.png') no-repeat center
  right;
$cases-mlm-2: url('../assets/images/mlm/bg_wrangler.png') no-repeat center right;
$cases-mlm-3: url('../assets/images/mlm/bg_montepio.png') no-repeat center right;
$cases-mlm-4: url('../assets/images/mlm/bg_rheem.png') no-repeat center right;
$cases-mlb-1: url('../assets/images/mlb/bg_andorinha.png') no-repeat center
  right;
$cases-mlb-2: url('../assets/images/mlb/bg_turma_da_monica.png') no-repeat
  center right;
$cases-mlb-3: url('../assets/images/mlb/bg_comercial_textil.png') no-repeat center right;
$cases-mlb-4: url('../assets/images/mlb/bg_mae_terra.png') no-repeat center
  right;
