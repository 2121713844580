@media (width <= 1024px) {
  .connect-button {
    max-width: calc(100% - rem($inner-space-50));
    font-size: rem($font-body-16);
  }

  .appstore-slide__connect-slide-img-container {
    padding: 0 rem($andes-spacing-12);
  }

  .appstore-slide {
    width: 100%;
  }
}
