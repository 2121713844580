.ui-ms-hero-ebooks {
  position: relative;
}

.ui-ms-hero-ebooks__image {
  position: absolute;
  right: 0;
  top: 0;
  width: 65%;
}

.ui-ms-hero-ebooks__content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: rem($width-container);
  padding: rem($andes-spacing-40) 0 0;
}

.ui-ms-hero-ebooks__title {
  color: $color-blue;
  font-family: $font-family-primary;
  font-size: rem($font-title-70);
  font-weight: $font-weight-extrabold;
  letter-spacing: rem($letter-spacing-5);
  line-height: rem($outer-space-65);
  margin-top: rem($outer-space-57);
}

.ui-ms-hero-ebooks__subtitle {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-size-20);
  font-weight: $font-weight-normal;
  line-height: rem($text-space-24);
  margin-bottom: rem($outer-space-45);
  margin-top: rem($outer-space-10);
  width: $width-50-percent;
}
