.connect-button {
  align-items: center;
  background: $color-blue;
  border-radius: rem($border-radius-5);
  border: none;
  color: $color-white;
  display: flex;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  height: rem($inner-space-44);
  justify-content: center;
  letter-spacing: rem($letter-spacing-3);
  line-height: rem($font-body-24);
  margin: 0 auto;
  margin-bottom: rem($inner-space-35);
  margin-top: rem($inner-space-70);
  max-width: rem($cont-351);
  text-decoration: none;
  width: 100%;
}

.appstore-slide {
  margin: 0 auto;
  width: 80%;
}

.appstore-slide__connect-slide-img-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.appstore-slide__connect-slide-img {
  height: 100%;
  max-height: rem($cont-80);
  object-fit: contain;
  width: 45%;
}

.appstore-slide .slick-track {
  align-items: center;
  display: flex;
}

.appstore-slide .slick-dots li {
  height: rem($andes-spacing-8);
  width: rem($andes-spacing-8);
}

.appstore-slide .slick-dots li button {
  height: rem($andes-spacing-8);
  width: rem($andes-spacing-8);
}

.appstore-slide .slick-dots li button::before {
  border-radius: 100%;
  border: 1px solid $color-white;
  font-size: 0;
  height: rem($andes-spacing-8);
  opacity: 1;
  width: rem($andes-spacing-8);
}

.appstore-slide .slick-dots li.slick-active button::before {
  background: $color-white;
  color: $color-white;
}

.appstore-slide .slick-dots {
  bottom: rem(-$inner-space-36);
}

.appstore-slide__connect-card {
  align-items: center;
  background: $color-white;
  border-radius: rem($border-radius-8);
  display: flex;
  height: rem($cont-134);
  justify-content: space-between;
  margin: 0 auto;
  max-width: rem($cont-242);
  padding: rem(0 $inner-space-40 0 $inner-space-15);
}

.appstore-slide__connect-card-link {
  text-decoration: none;
}

.appstore-slide__connect-card-title {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: rem($font-body-20);
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($font-body-24);
  margin-bottom: rem($inner-space-6);
}

.appstore-slide__connect-card-subtitle {
  color: $color-grey-7c;
  font-family: $font-family-primary;
  font-size: rem($font-body-14);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-16);
}
