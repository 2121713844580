@media (width <= 1024px) {
  .ui-ms-hero-ebooks {
    padding: 0 rem($inner-space-24);
  }

  .ui-ms-hero-ebooks__image {
    display: none;
  }

  .ui-ms-hero-ebooks__title {
    font-size: rem($font-size-44);
    line-height: rem($height-45);
    letter-spacing: rem($letter-spacing-6);
    margin-top: rem($inner-space-24);
  }

  .ui-ms-hero-ebooks__content {
    padding: rem($inner-space-80 0 $andes-spacing-40);
  }

  .ui-ms-hero-ebooks__subtitle {
    margin-bottom: rem($outer-space-30);
    margin-top: rem($inner-space-20);
    width: $width-100-percent;
  }
}

@media (width <= 1300px) {
  .ui-ms-hero-ebooks__image {
    width: 80%;
  }
}
