@media (width <= 1100px) {
  .learn-hero__cont-hero {
    padding: 0 rem($andes-spacing-20);
  }
}

@media (width <= 768px) {
  .learn-hero__title {
    font-size: rem($font-title-42);
    line-height: rem($font-title-42);
    margin-bottom: rem($andes-spacing-16);
  }

  .learn-hero__text {
    margin-bottom: 0;
  }
}
