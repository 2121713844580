.learning {
  margin-bottom: rem($andes-spacing-40);
}

.learning__cont-cards {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: rem($inner-space-10 $andes-spacing-20);
  justify-content: flex-start;
  margin: 0 auto;
  max-width: rem($width-container);
  width: 100%;
}

.learning-card {
  background: $color-yellow-card;
  border-radius: rem($andes-spacing-12);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  break-inside: avoid;
  margin-bottom: rem($inner-space-10);
  overflow: hidden;
  position: relative;
  transition: .4s;
  width: 32.1%;
}

.learning-card__image-card {
  overflow: hidden;
  padding-top: 0;
  position: relative;
  text-align: center;
}

.learning-card__card-img {
  width: 100%;
}

.learning-card__content-card {
  padding: rem($outer-space-26 $outer-space-26);
}

.learning-card__content-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: rem($andes-spacing-20);
  width: 100%;
}

.learning-card__text-card {
  height: auto;
  min-height: rem($inner-space-110);
  overflow: hidden;
}

.learning-card__content-icon {
  align-items: center;
  background: $color-pink;
  border-radius: 50%;
  border: 2px solid $color-pink;
  display: flex;
  height: rem($inner-space-54);
  justify-content: center;
  margin-right: rem($andes-spacing-16);
  width: rem($inner-space-54);
}

.learning-card__title {
  align-items: center;
  color: $color-grey-text;
  display: flex;
  font-family: $font-family-primary;
  font-size: rem($font-title-34);
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: rem($font-title-30);
  max-width: 70%;
  min-height: rem($inner-space-60);
}

.learning-card__description {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-body-18);
  font-weight: $font-weight-regular;
  line-height: rem($font-body-20);
  margin-bottom: rem($inner-space-10);
  min-height: rem($inner-space-60);
}

.learning-card__cont-btn {
  display: flex;
  justify-content: center;
  margin-top: rem($andes-spacing-20);
}

.learning-card__card-btn {
  background: $color-blue;
  border-radius: rem($inner-space-4);
  border: none;
  color: $color-white;
  display: block;
  font-family: $font-family-primary;
  font-size: rem($font-body-15);
  max-width: max-content;
  padding: rem($inner-space-12 $inner-space-30 $inner-space-10);
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
  width: 100%;
}

.learning-card__card-btn:hover {
  background: $color-pink-hover;
  color: $color-white;
  transition: 0.3s;
}
