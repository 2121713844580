@media (width <= 1023px) {
  .rockets__container {
    padding: rem(0 $andes-spacing-20 $andes-spacing-28);
  }

  .rockets__card {
    background-color: $color-blue;
  }

  .rockets__card--card-mlb {
    width: rem($cont-743);
    background: linear-gradient(
      60.21deg,
      $color-blue 22.66%,
      $color-blue 68.23%,
      $color-pink-gradient 105.2%
    );
  }

  .rockets__title {
    font-size: rem($font-body-24);
    line-height: rem($font-body-28);
    margin: 0;
    margin-bottom: rem(-$inner-space-70);
    max-width: rem($cont-333);
    padding: rem($andes-spacing-32) rem($andes-spacing-24) 0;
    text-align: left;
  }

  .rockets__steps {
    padding: 0 rem($andes-spacing-24) rem($andes-spacing-32);
  }
}

@media (width <= 768px) {
  .rockets__card-container {
    padding: rem($andes-spacing-20 0);
  }
}
