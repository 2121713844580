.section-ecosystems {
  background: $hero-gray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: rem($inner-space-80);
  padding-bottom: rem($inner-space-100);
  padding-top: rem($inner-space-72);
  position: relative;
  width: 100%;
  z-index: 1;
}

.section-ecosystems__container {
  max-width: rem($width-container);
  width: 100%;
}

.section-ecosystems__cont-title {
  margin: 0 auto;
  margin-bottom: rem($andes-spacing-40);
  max-width: rem($cont-660);
  padding: 0 rem($andes-spacing-20);
  text-align: center;
}

.section-ecosystems__title {
  color: $color-grey-text;
  font-family: $font-family-primary;
  font-size: rem($font-title-34);
  font-weight: $font-weight-regular;
  letter-spacing: rem($letter-space);
  line-height: rem($font-title-42);
  text-align: center;
}

.section-ecosystems__title--bold {
  color: $color-pink;
  font-weight: $font-weight-600;
}

.cards-section-ecosystems {
  width: 100%;
}

.cards-section-ecosystems__cards-list {
  list-style-type: none;
}
